<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <md-button
          class="md-primary float-right md-round md-sm"
          style="margin-top: -20px"
          :disabled="!tableHasContent"
          @click="onDownload"
        >
          <md-icon>get_app</md-icon>
          Download as XLS
        </md-button>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div class="md-layout-item md-size-50">
            <FormSelect
              v-model="filters.exam_type_id"
              label="Exam Type"
              icon="label_outline"
              :options="exam_types"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <FormSelect
              v-model="filters.exam_paid"
              label="Exams Paid"
              :options="[
                {id: 'all', name: 'ALL'},
                {id: true, name: 'YES'},
                {id: false, name: 'NO'},
              ]"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.from"
              name="dateFrom"
              label="Date From"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-date
              v-model="filters.to"
              name="dateTo"
              label="Date To"
            />
          </div>

          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getExitExams"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormDate, FormSelect } from '@/components/Inputs';
import moment from 'moment';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    VueTable,
    FormDate,
    FormSelect,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'invoice',
          mask: 'invoice',
          sortable: true,
        },
        {
          title: 'exam_date',
          mask: 'Exam Date',
          sortable: true,
        },
        {
          title: 'exam_type',
          mask: 'Type of Exam',
          sortable: true,
        },
        {
          title: 'student_number',
          mask: 'Student Number',
          sortable: true,
        },
        {
          title: 'student_name',
          mask: 'Student Name',
          sortable: true,
        },
        {
          title: 'exam_paid',
          mask: 'Exam paid',
        },
      ],
      values: {},
      actions: [],
    },
    country: {},
    filters: {
      from: moment().subtract(1, 'month').startOf('day'),
      to: moment().startOf('day'),
      exam_paid: 'all',
      exam_type_id: 'all',
    },
    all: { name: 'ALL', id: 'all' },
    exam_types: [],
    loading: false,
    params: {},
  }),
  computed: {
    tableHasContent() {
      return this.vTable.values?.data?.length > 0;
    },
  },
  mounted() {
    this.$refs.vtable.init();
    this.getExamTypes();
  },
  methods: {
    getExitExams(params) {
      this.params = { ...this.filters, ...params };

      this.request('end_exam_report', 'get', { ...this.params }, ({ data }) => {
        this.vTable.values = data;
      });
    },
    getExamTypes() {
      this.request('exam_types', 'get', null, ({ data }) => {
        this.exam_types = data.map((x) => {
          x.id = x.exam_type_id;
          return x;
        });
        this.exam_types.unshift(this.all);
      });
    },
    onFilter() {
      this.$refs.vtable.init();

      this.params = { ...this.filters, ...this.params };
    },
    onDownload() {
      this.loading = true;
      this.request('end_exam_report', 'download', { ...this.filters, download: true }, ({ data }) => {
        const filename = `end_exam_report_${moment().format('YYYY_MM_DD')}.xlsx`;
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>
